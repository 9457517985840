.App {
  min-height: 100vh;
}

.with__bg {
  margin: 0;
  /* background-color: #f5f9fb; */
  background-image: url(./bg.png);
  background-repeat: no-repeat, no-repeat;
  background-position: right top;
  background-size: 50%;
}

.xlarge__text {
  font-family: Montserrat;
  font-style: normal;
  font-weight: bold;
  font-size: 40px;
  line-height: 49px;
  color: #1e1e1e;
}

.small__text {
  font-family: Arial;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 29px;
  text-align: center;
  color: #1e1e1e;
}

.team_text {
  font-family: Arial;
  font-style: normal;
  font-weight: normal;
  font-size: 22px;
  line-height: 27px;
}

.team__info {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 290px;
  margin: auto;
}

.team__title {
  font-family: Montserrat;
  font-style: normal;
  font-weight: bold;
  font-size: 28px;
  line-height: 34px;
  color: #000000;
}

.team__subtitle {
  font-family: Arial;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 29px;
  color: #8a8a8a;
}

.medium__text {
  font-family: Montserrat;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 29px;
  text-transform: uppercase;
  color: #1e1e1e;
}

.light__color {
  color: #a8a8a8;
}

.__dark__color {
  color: #000 !important;
}

.__theme__color {
  color: #26944b !important;
}

.__yellow__color {
  color: #d8d74a !important;
}

.__alert__color {
  color: red !important;
}

.app__container {
  margin: 0;
  padding: 160px 120px;
}

.app__header {
  position: fixed;
  top: -1px;
  left: 0;
  right: 0;
  margin: 0;
  padding: 0 30px;
  height: 100px;
  align-items: center;
  justify-content: space-between;
  z-index: 999;
  transition: 0.3s;
}

.header__bg {
  height: 100px;
  background-color: #fff;
  box-shadow: 0px 10px 20px -2px rgb(38 148 75 / 15%);
}

.app__logo {
  height: 100%;
  border-radius: 8px;
}

a:hover {
  text-decoration: none;
}

nav a {
  margin: 0 15px;
  font-family: Arial;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 24px;
  color: #1e1e1e;
}

@media all and (device-width: 768px) and (device-height: 1024px) and (orientation:portrait) {
  nav a {
    margin: 0 7px;
    font-size: 9px;
  }
  .card {
    width: 340px;
  }
}

@media all and (device-width: 1024px) and (device-height: 768px) and (orientation:landscape) {
  nav a {
    margin: 0 8px;
    font-size: 14px;
  }
}

@media all and (device-width: 1024px) and (device-height: 1366px) and (orientation:portrait) {
  nav a {
    margin: 0 8px;
    font-size: 14px;
  }
}

/* @media all and (device-width: 1366px) and (device-height: 1024px) and (orientation:landscape) {


    
    } */

nav a:hover {
  color: #26944b;
  text-decoration: none;
}

.nav-item.dropdown {
  padding: 0 18px;
}

.active__route {
  color: #26944b;
}

.home__with__bg nav a {
  color: #ffffff;
}

.home__with__bg nav .dropdown-item a {
  color: #1e1e1e;
}

.home__with__bg nav a:hover, .home__with__bg .active__route {
  color: #f1f019;
}

.header__bg nav a {
  color: #1e1e1e;
}

.header__bg nav a:hover, .header__bg .active__route {
  color: #26944b;
}

.home__page {
  padding: 0;
}

.player-wrapper {
  position: relative;
  padding-top: 56.25%;
  /* 720 / 1280 = 0.5625 */
}

.react-player {
  position: absolute;
  top: 0;
  left: 0;
}

.left__home, .right__home {
  padding: 80px 120px 60px 120px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
  /* min-height: 100vh; */
}

.card {
  margin-top: 35px;
  padding: 35px 50px;
  background: #ffffff;
  border: 0;
  box-shadow: 0px 10px 20px -2px rgba(38, 148, 75, 0.15);
  border-radius: 15px;
}

.btn {
  width: 350px;
  max-width: 100%;
  margin: 15px 0;
  padding: 18px;
  border-radius: 5px;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  text-align: center;
}

.btn-success {
  color: #ffffff;
  box-shadow: 0px 10px 20px -4px rgba(38, 148, 75, 0.42);
}

.highlight__button {
  color: #000;
  background-color: #f7f793;
  border-color: #f7f793;
  box-shadow: 0px 10px 20px -4px rgb(247 247 147 / 42);
}

.highlight__button:hover, .highlight__button:focus {
  color: #000;
  background-color: #F1F019;
  border-color: #F1F019;
}

.highlight__button:focus {
  box-shadow: 0 0 0 0.2rem rgb(245 245 92);
}

.link {
  font-family: Arial;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 24px;
  text-align: center;
  color: #26944b;
}

.link:hover {
  color: #26944b;
}

button:focus {
  outline: none;
}

/* .input__group {
  margin: 60px -15px 10px -15px;
} */

.range__viewer {
  font-family: Arial;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: -0.408px;
  color: #000000;
}

.__title {
  margin: 45px 0 13px 0;
  font-family: Montserrat;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
  color: #8a8a8a;
}

.__title span {
  font-family: Montserrat;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  letter-spacing: -0.24px;
  color: #2b2a29!important;
  border: 0!important;
}

.card .__title span {
  margin-left: 2px;
  font-family: Arial;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 20px;
  letter-spacing: -0.24px;
  color: #a8a8a8;
}

.__title__value {
  margin-bottom: 30px;
  font-family: Montserrat;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
  color: #000;
}

.slider_image_container {
  width: 185px;
  height: 130px;
  padding: 20px 30px 30px 30px;
  background: #fff;
  border-radius: 5px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: 0.3s;
}

.slider_image_container img {
  width: 100%;
  height: 100%;
  border-radius: 5px;
  object-fit: cover;
  transition: 0.3s;
}

.slider_image_container.active_image {
  transform: scale(1.2);
}

.slider_image_container.active_image img {
  box-shadow: 0px 10px 20px -2px rgb(38 148 75 / 25%);
}

.sign__button {
  background-color: transparent;
  border-radius: 10px;
  border: 2px solid #000;
  width: 30px;
  height: 30px;
  font-weight: bold;
  color: #26944b;
}

.range__value {
  min-width: 120px;
  font-family: Montserrat;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 20px;
  text-align: center;
  letter-spacing: -0.24px;
  color: #26944b;
}

.image_viewer {
  min-height: 100px;
}

.range_viewer {
  margin-top: 25px;
}

input[type="range"] {
  margin-bottom: 45px;
  -webkit-appearance: none;
  width: 100%;
  height: 13px;
  border-radius: 5px;
  background: #eef2f5;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.13);
  border-radius: 20px;
  outline: none;
  opacity: 0.7;
  -webkit-transition: 0.2s;
  transition: opacity 0.2s;
  cursor: pointer;
}

input[type="range"]:hover {
  opacity: 1;
}

input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background: #f1f019;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.13);
  cursor: pointer;
}

input[type="range"]::-moz-range-thumb {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background: #f1f019;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.13);
  cursor: pointer;
}

.center__group {
  max-width: 950px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.center__group>div {
  margin-bottom: 0;
  width: 290px;
}

.center__group .__title, .center__group input, .center__group .input-group-text {
  font-weight: bold;
  font-size: 30px;
  line-height: 37px;
}

.center__group input, .center__group .input-group-text {
  color: #26944B;
}

.form__lable {
  margin: 55px 0;
  display: flex;
  align-items: center;
}

.lable__count {
  margin-right: 10px;
  width: 32px;
  min-width: 32px;
  height: 32px;
  color: #ffffff;
  background: #26944b;
  border-radius: 50%;
  font-family: Arial;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 21px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.lable__title {
  font-family: Arial;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 20px;
  letter-spacing: -0.24px;
  color: #2b2a29;
}

.formgroup__title {
  padding-bottom: 15px;
  font-family: Arial;
  font-style: normal;
  font-weight: normal;
  font-size: 17px;
  line-height: 20px;
  letter-spacing: -0.24px;
  color: #000000;
}

.form-group label {
  margin: 0;
  font-family: Arial;
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  line-height: 12px;
  text-transform: uppercase;
  color: #000000;
}

.form-group input, .form-group span, .form-group textarea {
  background: transparent;
  border: 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.5);
  border-radius: 0;
}

.form-group span {
  padding-right: 0;
  color: #8a8a8a;
}

textarea:focus, input:focus {
  box-shadow: none !important;
}

.form-check {
  min-width: 160px;
}

.form-group label.form-check-label {
  padding: 8px 0;
  font-family: Arial;
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 18px;
  letter-spacing: -0.408px;
  color: rgba(30, 30, 30, 0.85);
  text-transform: capitalize;
}

input[type="checkbox"], input[type="radio"] {
  margin-top: 9px;
}

.valid-feedback, .invalid-feedback {
  text-transform: capitalize;
}

.form-check .form-group input, .form-check-label {
  cursor: pointer;
}

.react-calendar {
  padding: 32px;
  width: 470px;
  background: #ffffff;
  box-shadow: 0px 10px 20px -2px rgb(0 0 0 / 15%);
  border: 0;
  border-radius: 10px;
}

.react-calendar__tile--active {
  border-radius: 50%;
}

.react-calendar__tile {
  width: 58px;
  height: 58px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

abbr[data-original-title], abbr[title] {
  color: #6e7781;
  text-decoration: none;
}

.summary__image {
  width: 135px;
  height: 90px;
  min-height: 90px;
  display: flex;
  align-items: center;
  margin-bottom: 25px;
}

.summary__image img {
  width: 100%;
  height: 100%;
  border-radius: 5px;
  object-fit: cover;
}

.custom-select {
  height: 50px;
  box-shadow: 0px 5px 10px rgb(0 0 0 / 10%);
  border-radius: 4px;
  border: 0;
  font-family: Arial;
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: -0.408px;
  text-transform: uppercase;
  color: rgba(0, 0, 0, 0.85);
  cursor: pointer;
}

/* ============================= Icon Burger CSS START =============================*/

.icon-burger {
  position: relative;
  width: 24px;
  height: 18px;
  margin: 0 auto;
}

.icon-burger__bar {
  width: 100%;
  height: 2px;
  background: #26944b;
  display: block;
  position: absolute;
  left: 0;
  -webkit-transition: all 0.3s ease-in;
  transition: all 0.3s ease-in;
}

.icon-burger:hover .icon-burger__bar {
  opacity: 0.8;
}

.icon-burger__bar:nth-child(1) {
  top: 0;
  width: 90%;
}

.icon-burger__bar:nth-child(2) {
  top: 8px;
}

.icon-burger__bar:nth-child(3) {
  top: 16px;
  width: 70%;
}

.toggle__active .icon-burger__bar:nth-child(1) {
  top: 8px;
  width: 100%;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.toggle__active .icon-burger__bar:nth-child(2) {
  opacity: 0;
}

.toggle__active .icon-burger__bar:nth-child(3) {
  top: 8px;
  width: 100%;
  -webkit-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

.app__header>div {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 75px;
}

.nav__toggle {
  display: none;
  background: #fff;
  padding: 10px;
  border-radius: 50%;
  width: 50px;
  min-width: 50px;
  height: 50px;
  min-height: 50px;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
}

.header__bg .nav__toggle {
  box-shadow: none;
}

.card .__title {
  font-size: 16px;
  margin: 0 0 10px 0;
}

.react-multiple-carousel__arrow {
  background: #f1f019;
  box-shadow: 0px 10px 20px -2px rgba(38, 148, 75, 0.1);
  opacity: 0.8;
}

.react-multiple-carousel__arrow:hover {
  background: #f1f019;
  opacity: 1;
}

.react-multiple-carousel__arrow::before {
  color: #000;
}

.react-multiple-carousel__arrow--left::before {
  content: " \2190";
}

.react-multiple-carousel__arrow--right::before {
  content: " \2192";
}

button:disabled {
  opacity: 0.2;
  cursor: not-allowed;
}

.form__box {
  padding-right: 120px;
}

.form-group {
  margin-bottom: 40px;
}

.card .form-group:last-child {
  margin: 0;
}

.text-align-center {
  text-align: center;
}

.left-align {
  text-align: left;
}

.right-align {
  text-align: right;
}

.title__box {
  width: 210px;
  text-align: right;
}

.input-group-prepend {
  text-transform: uppercase;
}

.modal-content {
  background: #FFFFFF;
  border-radius: 15px;
  border-color: #fff;
}

.modal-dialog-scrollable .modal-content {
  overflow: unset;
}

.modal-header {
  border: 0;
  padding: 40px;
}

.modal-body {
  padding: 0 50px 50px 50px;
}

.modal-header .close {
  position: absolute;
  right: 0;
  top: 0;
  background: #fff;
  border-radius: 50%;
  width: 53px;
  height: 53px;
  font-size: 30px;
  font-weight: 300;
  line-height: 33px;
  opacity: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal-header .close:hover {
  opacity: 1!important;
}

.table td, .table th {
  padding: .75rem 4px;
  border: 0!important;
  text-align: center;
  vertical-align: inherit!important;
}

.table th {
  position: sticky;
  top: 0px;
  background-color: rgb(112 183 133);
}

.table td:first-child, .table th:first-child {
  border-radius: 5px 0 0 5px;
}

.table td:last-child, .table th:last-child {
  border-radius: 0 5px 5px 0;
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color: #fff;
}

.table-striped tbody tr:nth-of-type(even) {
  background-color: rgba(241, 240, 25, 0.2);
}

/* Chrome, Safari, Edge, Opera */

input::-webkit-outer-spin-button, input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */

input[type=number] {
  -moz-appearance: textfield;
}

nav {
  display: flex;
  align-items: center;
  justify-content: center;
}

.nav-link {
  padding: 0;
}

.dropdown-item:focus, .dropdown-item:hover {
  background-color: transparent;
}

.dropdown-menu {
  background: #FFFFFF;
  box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
  border-radius: 5px;
}

.dropdown-item {
  margin: 0;
  padding: 8px;
}

.modal-body.success_popup {
  text-align: center;
  padding: 50px;
}

.success_popup .__title {
  margin: 30px 0;
  font-family: Montserrat;
  font-style: normal;
  font-weight: bold;
  font-size: 32px;
  line-height: 39px;
  color: #26944B;
}

.success_popup .__subtitle {
  font-family: Arial;
  font-style: normal;
  font-weight: normal;
  font-size: 32px;
  line-height: 39px;
  color: #D8D74A;
}

.success_popup .__message {
  margin: 50px 0;
  font-family: Arial;
  font-style: normal;
  font-weight: normal;
  font-size: 30px;
  line-height: 139.4%;
  text-align: left;
  color: #000000;
}

.contact__box {
  margin: 30px 0;
}

.contact__title {
  margin-bottom: 15px;
  font-family: Montserrat;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 29px;
  color: #26944B;
}

.contact__value {
  font-family: Arial;
  font-style: normal;
  font-weight: normal;
  font-size: 22px;
  line-height: 142.6%;
  color: rgba(30, 30, 30, 0.85);
}

.social__icon img {
  border-radius: 8px;
  transition: 0.3s;
}

.social__icon img:hover {
  box-shadow: 0px 0px 5px grey;
}

/* ================================= Loader Css Start ================================= */

/* Absolute Center Spinner */

.loading {
  position: fixed;
  z-index: 999999;
  height: 2em;
  width: 2em;
  overflow: show;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

/* Transparent Overlay */

.loading:before {
  content: "";
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #00000021;
}

/* :not(:required) hides these rules from IE9 and below */

.loading:not(:required) {
  /* hide "loading..." text */
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

.loading:not(:required):after {
  content: "";
  display: block;
  font-size: 10px;
  width: 1em;
  height: 1em;
  margin-top: -0.5em;
  -webkit-animation: spinner 150ms infinite linear;
  -moz-animation: spinner 150ms infinite linear;
  -ms-animation: spinner 150ms infinite linear;
  -o-animation: spinner 150ms infinite linear;
  animation: spinner 150ms infinite linear;
  border-radius: 0.5em;
  -webkit-box-shadow: rgba(109, 155, 48, 0.75) 1.5em 0 0 0, rgba(109, 155, 48, 0.75) 1.1em 1.1em 0 0, rgba(109, 155, 48, 0.75) 0 1.5em 0 0, rgba(109, 155, 48, 0.75) -1.1em 1.1em 0 0, rgba(109, 155, 48, 0.75) -1.5em 0 0 0, rgba(109, 155, 48, 0.75) -1.1em -1.1em 0 0, rgba(109, 155, 48, 0.75) 0 -1.5em 0 0, rgba(109, 155, 48, 0.75) 1.1em -1.1em 0 0;
  box-shadow: rgba(109, 155, 48, 0.75) 1.5em 0 0 0, rgba(109, 155, 48, 0.75) 1.1em 1.1em 0 0, rgba(109, 155, 48, 0.75) 0 1.5em 0 0, rgba(109, 155, 48, 0.75) -1.1em 1.1em 0 0, rgba(109, 155, 48, 0.75) -1.5em 0 0 0, rgba(109, 155, 48, 0.75) -1.1em -1.1em 0 0, rgba(109, 155, 48, 0.75) 0 -1.5em 0 0, rgba(109, 155, 48, 0.75) 1.1em -1.1em 0 0;
}

/* Animation */

@-webkit-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@-moz-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@-o-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

/* ================================= Loader Css End ================================= */

.home__container {
  margin: 100px 120px;
}

.home__container .app__container {
  padding: 0;
}

.home__title {
  margin-bottom: 96px;
  font-family: Montserrat;
  font-style: normal;
  font-weight: bold;
  font-size: 40px;
  text-align: center;
  line-height: 49px;
  color: #26944B;
}

.home__small__text {
  margin-bottom: 22px;
  font-family: Arial;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 141.1%;
  color: #1E1E1E;
}

.home__image__container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.home__image__box {
  margin-bottom: 57px;
  width: 230px;
  text-align: center;
}

.home__image {
  margin-bottom: 22px;
  width: 80%;
  height: 100px;
  object-fit: contain;
}

.home__image__small__title {
  font-family: Arial;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 24px;
  text-align: center;
  color: rgba(0, 0, 0, 0.5);
}

.home__image__medium__title {
  font-family: Montserrat;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 29px;
  text-align: center;
  color: rgba(0, 0, 0, 0.5);
}

.home__container .card {
  background: transparent!important;
  padding: 0;
  box-shadow: none;
  cursor: pointer;
}

.home__container .card-body {
  padding: 0;
}

.home__container .card-subtitle {
  margin-top: 17px;
  text-align: right;
  font-family: Arial;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  color: rgba(0, 0, 0, 0.5)!important;
}

.home__container .card-title {
  margin-top: 23px;
  font-family: Montserrat;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  color: rgba(30, 30, 30, 0.85)!important;
}

.home__container .card-text {
  margin-top: 17px;
  font-family: Arial;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 141.1%;
  color: #1E1E1E!important;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.home__container .card-text h1, .home__container .card-text h2, .home__container .card-text h3, .home__container .card-text h4, .home__container .card-text h5, .home__container .card-text h6, .home__container .card-text p {
  font-size: inherit;
  margin: 0;
}

.card-img-top {
  height: 239px;
  object-fit: cover;
  object-position: center;
}

.rating_box {
  display: flex;
  margin: 30px;
}

.rating_image img {
  width: 68px;
  height: 68px;
  border-radius: 50%;
  margin-right: 32px;
}

.rating__title {
  font-family: Montserrat;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
  color: #000000;
}

.rating__star {
  font-family: Arial;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  color: rgba(0, 0, 0, 0.5);
}

.rating__star span:first-child {
  margin-right: 13px;
}

.rating__text {
  font-family: Arial;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 141.1%;
  color: #1E1E1E;
}

.footer {
  padding: 67px 126px;
  background: #f5f9fb;
}

.footer__title {
  margin-bottom: 22px;
  font-family: Montserrat;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 29px;
  color: #000000;
}

.footer__text {
  font-family: Arial;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 141.1%;
  color: #1E1E1E;
}

.footer__text__light {
  font-family: Arial;
  font-style: normal;
  font-weight: normal;
  font-size: 22px;
  line-height: 141.1%;
  color: #8A8A8A;
}

.footer_navlink {
  display: grid;
}

.footer_navlink a:hover {
  color: #333;
}

.footer_navlink a.active__route {
  color: #26944b;
}

a.sub__link {
  padding-left: 30px;
}

a.sub__link:before {
  position: absolute;
  content: "-";
  left: 30px;
}

.footer_contact_box {
  margin: 24px 0;
  display: flex;
  align-items: flex-start;
}

.contact_icon {
  margin-top: 4px;
  margin-right: 15px;
}

.bottom_footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.bottom_footer div {
  display: flex;
}

.react-multi-carousel-list {
  min-height: 130px;
}

.img-hover-zoom {
  height: 239px;
  overflow: hidden;
}

.img-hover-zoom img {
  transition: transform .5s ease;
}

.img-hover-zoom:hover img {
  transform: scale(1.3);
}

.gray_bg {
  filter: grayscale(1);
}

@media only screen and (max-width: 767px) {
  .with__bg {
    margin: -145px -25px 0px -25px;
    padding: 130px 0;
    background-size: unset;
  }
  .app__header {
    padding: 15px;
    align-items: unset;
    transition: 0s;
  }
  .nav__toggle {
    display: flex;
  }
  nav {
    display: none;
  }
  nav a {
    margin: 20px;
    font-family: Montserrat;
    font-style: normal;
    font-weight: bold;
    font-size: 30px;
    line-height: 37px;
    color: #000000 !important;
  }
  .app__header>div {
    width: 100%;
  }
  .toggle__active.app__header {
    display: block;
    height: 100%;
    background-color: #fff;
  }
  .toggle__active>div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }
  .toggle__active>nav {
    display: grid;
    width: 100%;
    margin-top: 40px;
  }
  .home__page .right__home {
    display: none;
  }
  .app__container {
    padding: 145px 25px 25px 25px;
  }
  .left__home {
    padding: 0;
    min-height: unset;
  }
  .xlarge__text {
    font-family: Montserrat;
    font-style: normal;
    font-weight: bold;
    font-size: 26px;
    line-height: 32px;
    color: #ffffff;
  }
  .card {
    padding: 15px 35px;
  }
  .home__page .card {
    background: rgba(177, 177, 177, 0.3);
    backdrop-filter: blur(8px);
    border-radius: 10px;
  }
  .home__page .card div {
    color: #fff;
  }
  .small__text {
    font-size: 20px;
    line-height: 24px;
  }
  .medium__text {
    font-size: 20px;
    line-height: 24px;
  }
  .__title {
    font-size: 18px;
    line-height: 22px;
  }
  .range__value {
    font-size: 16px;
    line-height: 20px;
    min-width: unset;
    padding: 0 20px;
  }
  .range_viewer {
    font-size: 14px;
    line-height: 18px;
    letter-spacing: -0.408px;
  }
  .sign__button {
    width: 18px;
    height: 18px;
    border-radius: 5px;
    font-size: 14px;
    line-height: 0px;
    padding: 0;
    border: 1px solid #000;
  }
  .summary__image {
    width: auto;
    min-height: 40px;
    padding: 0;
  }
  button {
    max-width: 100%;
  }
  .loan__page .card {
    margin-left: 40px;
  }
  .form__lable {
    margin: 40px 0 0 0;
  }
  .lable__title {
    font-size: 17px;
    line-height: 20px;
    letter-spacing: -0.24px;
  }
  .form__box {
    padding: 0;
    height: 100%;
  }
  .form__indicator {
    position: relative;
  }
  .form__indicator:before {
    position: absolute;
    top: 57px;
    left: 12px;
    right: 0;
    content: "";
    background: #e0e0e0;
    width: 8px;
    height: 100%;
    z-index: -1;
  }
  .complete__form::before {
    background: rgba(241, 240, 25, 0.6);
  }
  .incomplete__form .lable__count {
    background: #bdbdbd;
  }
  .incomplete__form .lable__title {
    color: #a8a8a8;
  }
  .active__form .lable__count {
    background: #26944b;
  }
  .active__form .lable__title {
    color: #2b2a29;
  }
  .loan__page .card {
    height: 0;
    display: none;
  }
  .active__form .card {
    height: max-content;
    display: block;
  }
  a, .btn {
    width: 100% !important;
    max-width: 100% !important;
  }
  .slider_image_container {
    width: 160px;
    height: 115px;
  }
  .card .left-align, .card .right-align {
    text-align: center;
  }
  .table td, .table th {
    font-family: Arial;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 15px;
  }
  .modal-body {
    padding: 0 1rem 1rem 1rem;
  }
  .modal-header .close {
    top: 10px;
    right: 10px;
  }
  .center__group {
    display: block;
    text-align: center;
  }
  .center__group>div {
    width: unset;
  }
  .mobile__margin {
    margin-top: 80px;
  }
  .home__container {
    margin: 100px 0px;
  }
  .home__image__container {
    display: block;
  }
  .home__image__box {
    margin: 30px auto;
  }
  .footer {
    padding: 20px;
  }
  .footer__title {
    margin-top: 40px;
  }
  .bottom_footer {
    display: block;
  }
  .bottom_footer div {
    margin-bottom: 15px;
    justify-content: center;
  }
  .home__title {
    font-size: 26px;
    line-height: 32px;
  }
  .home__small__text {
    font-size: 16px;
    line-height: 141.1%;
  }
  .home__image__medium__title {
    font-size: 18px;
    line-height: 22px;
  }
  .rating_image img {
    margin-right: 22px;
    width: 48px;
    height: 48px;
  }
  .rating_box {
    margin: 10px 0;
  }
  .rating__title {
    font-size: 16px;
    line-height: 20px;
  }
  .rating__star {
    font-size: 14px;
    line-height: 17px;
  }
  .rating__text {
    font-size: 14px;
    line-height: 141.1%;
  }
  .react-calendar {
    margin: 30px 0;
  }
  .react-calendar__tile {
    width: unset;
    height: unset;
  }
  .dropdown-menu.show {
    background-color: #c9e4d2;
    box-shadow: none;
    border: 0;
    border-radius: 15px;
  }
  .dropdown-item a {
    font-size: 18px;
  }
}